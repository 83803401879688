/* From Uiverse.io by zanina-yassine */
/* Remove this container when use*/
.component-title {
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: 0;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: #888;
  text-align: center;
}

/* The switch - the box around the slider */
.checkbox-container {
  width: 51px;

  position: relative;
}

/* Hide default HTML checkbox */
.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.switch {
  width: 40px; /* Reduced width */
  height: 20px; /* Reduced height */
  display: block;
  background-color: #e9e9eb;
  border-radius: 10px; /* Adjusted for smaller switch */
  cursor: pointer;
  transition: all 0.2s ease-out;
}

/* The slider */
.slider {
  width: 16px; /* Reduced width */
  height: 16px; /* Reduced height */
  position: absolute;
  left: 2px; /* Starting position of the slider */
  top: 2px; /* Centering within the smaller switch */
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.checkbox:checked + .switch {
  background-color: #298cd3;
}

.checkbox:checked + .switch .slider {
  left: 22px; /* Adjusted for new position on smaller switch */
  top: 2px;
}
.glass-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  color: white; /* Default text color for better readability */
}
.ant-table-tbody > tr > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-tbody
  > tr {
  padding: 10px 16px !important;
}
.ant-popover-inner-content {
  padding: 0;
}
