@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

:root {
  --primary-color: #4f46e5; /* Default primary color */
  --primary-color-light: #6366f1; /* Slightly lighter (e.g., like 'indigo-500') */
  --primary-color-dark: #3730a3; /* Darker (e.g., like 'indigo-700') */
  --primary-color-lighter: #818cf8; /* Much lighter */
  --primary-color-darker: #312e81; /* Much darker */
  --primary-color-hover: #5b55f5;
}
:root {
  --indigo-50: #eef2ff;
  --indigo-100: #e0e7ff;
  --indigo-200: #c7d2fe;
  --indigo-300: #a5b4fc;
  --indigo-400: #818cf8;
  --indigo-500: #6366f1;
  --indigo-600: #4f46e5;
  --indigo-700: #4338ca;
  --indigo-800: #3730a3;
  --indigo-900: #1e3a8a;
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-primary-light {
  background-color: var(--primary-color-light);
}

.bg-primary-dark {
  background-color: var(--primary-color-dark);
}

.bg-primary-lighter {
  background-color: var(--primary-color-lighter);
}

.bg-primary-darker {
  background-color: var(--primary-color-darker);
}

.text-primary {
  color: var(--primary-color);
}

.text-primary-dark {
  color: var(--primary-color-dark);
}
.primary-button {
  background-color: var(--primary-color);
  transition: background-color 0.2s ease;
}

.primary-button:hover {
  background-color: var(--primary-color-light);
}

.primary-button-active {
  background-color: var(--primary-color-dark);
}
body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Apply custom styles for Ant Design components */
.ant-btn-primary {
  background-color: var(--indigo-500) !important;
  border-color: var(--indigo-500) !important;
}

.ant-btn-primary:hover {
  background-color: var(--primary-light) !important;
  border-color: var(--primary-light) !important;
}

.ant-input:focus {
  border-color: var(--indigo-500) !important;
  box-shadow: 0 0 0 2px rgba(var(--primary-light), var(--shadow-opacity)) !important;
}

.ant-select:focus {
  border-color: var(--indigo-500) !important;
  box-shadow: 0 0 0 2px rgba(var(--primary-light), var(--shadow-opacity)) !important;
}

/* General input styles */
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="date"],
input[type="phone"],
input[type="number"],
input[type="password"],
textarea,
select {
  width: 100%;
  padding: 0.2rem 1rem;
  border: 1px solid #d1d5db; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  outline: none; /* Remove default outline */
  border-width: 1px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease; /* Smooth transition */
}

/* Input focus styles */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
input[type="phone"]:focus,
textarea:focus,
select:focus {
  border-color: var(--indigo-500);
  box-shadow: 0 0 0 2px rgba(var(--indigo-300), 0.3);
}
